import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { Link } from "@reach/router"
import { teamURL } from "../../lib/urls"
import "./OffplanConsultant.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanConsultant = ({ itemData, teamData, pageData, isPreviewEnabled }) => {

  const [teamList, setTeamList] = useState([])
  const { isMobile } = useDeviceMedia()

  useEffect(() => {
    let randomResult = teamData?.sort(()=> Math.random() - 0.5)
    setTeamList(randomResult)
   
  }, [teamData]);

  if (!teamData?.length > 0) return null


  const consultantData =teamList?.slice(0,10)

  const ImageRenderList = ({ item, imagename }) => {
    
    return (
     
      <GGFXImage
      ImageSrc={item.node?.image}
      altText={`${item.node?.name}`}
      imagetransforms={item?.node?.ggfx_results}
      renderer="srcSet"
      imagename={imagename}
      strapiID={item?.node?.strapi_id}
    />
    )
  }

  return (
    <div className="offplan-consultant-wrapper">
      <Container>
        <ScrollAnimation
          animateIn="animate__slideInUp"
          animateOnce
          delay={100}
          offset={10}
        >
          <div className="consultant-content-sections">
            <h2>{itemData?.title}</h2>
            {(itemData?.content?.data?.content || (itemData?.content && isPreviewEnabled))&&
            <p className="description">
              <ContentModule Content={itemData?.content?.data?.content || itemData?.content} />
            </p>
            }
          </div>

          <div className="slider-consultant-sec">
            <CustomSlider
              noDots
              showArrows
              showMeThree
              iconchange
              slidecount={isMobile ? 1.8 : 6}
              className="consultant-offplan-slider"
            >
              {consultantData?.map((item, index) => {
                return (
                  <ScrollAnimation
                    className="consultant-slider-section"
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                    key={item?.node?.strapi_id}
                  >
                    <div className="consultant-slider-item">
                      <div className="image-section">
                        <Link to={`${teamURL}${item?.node?.slug}`}>
                          <ImageRenderList
                            item={item}
                            imagename={"team.image.consultant"}
                          />
                        </Link>
                      </div>
                      <Link to={`${teamURL}${item?.node?.slug}`}>
                        <p className="consultant-name">{item?.node?.name}</p>
                      </Link>
                      <p className="consultant-designation">
                        {item?.node?.designation}
                      </p>
                    </div>
                  </ScrollAnimation>
                )
              })}
            </CustomSlider>
          </div>
        </ScrollAnimation>
      </Container>
    </div>
  )
}

export default OffplanConsultant
